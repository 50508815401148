import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },

  {
    exact: true,
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },
  {
    exact: true,
    path: "/login-otp-verification",
    layout: LoginLayout,
    component: lazy(() =>
      import("src/views/auth/LogIn/loginOtpVerify")
    ),
  },
  {
    exact: true,
    path: "/forget-password",

    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },
  {
    exact: true,
    path: "/user-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/userManagement")),
  },
  {
    exact: true,
    path: "/add-user",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/userManagement/adduser")),
  },
  {
    exact: true,
    path: "/view-user-details",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/userManagement/userDetails")
    ),
  },
  {
    exact: true,
    path: "/view-useractivity",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/userManagement/viewuseractivity")),
  },
  {
    exact: true,
    path: "/view-userloginactivity",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/userManagement/viewuserloginactivity")),
  },
  {
    exact: true,
    path: "/content-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticContent/contentManagement")),
  },
  {
    exact: true,
    path: "/view-content",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticContent/viewContent")),
  },
  {
    exact: true,
    path: "/edit-content",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticContent/editContent")),
  },
  {
    exact: true,
    path: "/staff-manage",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/UserstaffManagement/staffmanage")),
  },
  {
    exact: true,
    path: "/view-staff",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/UserstaffManagement/viewstaff")),
  },
  {
    exact: true,
    path: "/edit-staff",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/UserstaffManagement/editStaff")),
  },
  {
    exact: true,
    path: "/change-password",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/changePassword/changepasssword")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];