// const url = "https://v2py-expertchatbot.mobiloitte.io";
const url = "https://v3py-expertchatbot.mobiloitte.io";

// const url = "https://pytest-kyle.mobiloitte.io";
// const url = "http://172.16.6.43:8080/"


const admin = `${url}/Admin`;

const ApiConfig = {

  userLogin: `${admin}/login/`,
  forgotPassword: `${admin}/forgetPassword/`,
  verifyOTP: `${admin}/verifyotp/`,
  resetPassword: `${admin}/resetpassword/`,
  changepassword: `${admin}/ChangePassword/`,
  loginOTPVerify: `${admin}/loginOTPVerify`,
  changePasswordSendOTP: `${admin}/changePasswordSendOTP`,
  changePasswordOTPVerify: `${admin}/changePasswordOTPVerify`,
  listUser: `${admin}/AlluserDetail/`,
  userDetails: `${admin}/viewuserdetail/`,
  blockUnblock: `${admin}/blockUnblockUser/`,
  deleteUser: `${admin}/deleteUser`,
  getListStatic: `${admin}/staticcontent/allcontent/`,
  getSingleStaticContent: `${admin}/staticcontent/singlecontent/`,
  editStaticContent: `${admin}/staticcontent/updatecontent/`,
  AddUser : `${admin}/AddUser/`,
  dashboardCount: `${admin}/dashboard/`,
  
  // staff manage
  AllStaffDetails: `${admin}/AllStaffDetails/`,
  singleStaffView: `${admin}/singleStaffView/`,
  EditStaff: `${admin}/EditStaff/`,
 
// User Management Activities
loginActivity: `${admin}/loginActivity/`,
UserActivity: `${admin}/UserActivity/`,
};

export default ApiConfig;

