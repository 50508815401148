/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import { RxCrossCircled } from "react-icons/rx";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles, Dialog, Typography, DialogActions, DialogContent, Slide
} from "@material-ui/core";
import Logo from "src/component/Logo";
import { FaSignOutAlt } from "react-icons/fa";
import { MdSupervisedUserCircle } from "react-icons/md";
import { CgLogIn } from "react-icons/cg";
import { ImHome3 } from "react-icons/im";
import { GiToken } from "react-icons/gi";
import { RiLockPasswordFill } from "react-icons/ri";
import NavItem from "./NavItem";
import { AuthContext } from "src/context/Auth";
import { ImUserCheck, ImGift, ImQuestion, } from "react-icons/im";
import { BiBookContent } from "react-icons/bi";
import { MdSupervisorAccount } from "react-icons/md";
import { toast } from 'react-toastify';

const sections = [
  {
    items: [
      {
        title: "Dashboard",
        icon: ImHome3,
        href: "/dashboard",
      },
      {
        title: "User Management",
        icon: MdSupervisedUserCircle,
        href: "/user-management",
      },

      {
        title: "Content Management",
        href: "/content-management",
        icon: BiBookContent,
      },

      {
        title: "Staff Management",
        href: "/staff-manage",
        icon: MdSupervisorAccount,
      },

      {
        title: "Change Password",
        href: "/change-password",
        icon: RiLockPasswordFill,
      },
    ],
  },
];
const sectionsBelow = [
  {
    items: [
      // {
      //   title: "Terms and Conditions",
      //   //icon: PieChartIcon,
      //   href: "/terms",
      // },
      // {
      //   title: "Logout",
      //   href: "/dashboard",
      //   icon: IoLogOut,
      // },
      // {
      //   title: "Privacy Policy",
      //   //icon: PieChartIcon,
      //   href: "/privacy-policy",
      // },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0, state, setSelectedTab }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth, state, setSelectedTab, }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth, state, setSelectedTab, }) {
  const key = item.title + depth;
  const isActive = matchPath(pathname, {
    path: item.href,
    exact: false, // Set to false if you want sub-routes to be considered active
  });
  if (item.items) {
    // Set 'open' to true if the current route or any sub-module is active
    const open = isActive || item.items.some((subItem) => {
      const subItemPath = subItem.href;
      return matchPath(pathname, {
        path: subItemPath,
        exact: false, // Set to false if you want sub-routes to be considered active
      });
    });

    // if (item.items) {
    //   const open = matchPath(pathname, {
    //     path: item.href,
    //     exact: false,
    //   });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          state,
          setSelectedTab,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
        tabview={item.tabview}
        setSelectedTab={(item) => setSelectedTab(item)}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: `#ffffff !important`,
    boxShadow: " 0 0.1rem 0.7rem rgb(0 0 0 / 10%)",
  },
  desktopDrawer: {
    width: 256,
    top: 0,
    height: "100%",
    background: `#A1A5B7 !important`,
    border: "none",
  },
  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(1),
    color: `${theme.palette.text.primary} !important`,
  },

  Terms: {
    color: theme.palette.text.primary,

    // padding: "17px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    borderLeft: "solid 8px transparent",
    borderRadius: 0,
    fontSize: "13px",
    "& .MuiButton-label": {
      padding: "10px",

    },
    "&:hover": {
      "& .MuiButton-label": {
        color: "#fff !important",
        background: "#3A96DD",
        padding: "10px",
        borderRadius: "9px",
        fontWeight: theme.typography.fontWeightRegular,
        "& $title": {
          fontWeight: theme.typography.fontWeightMedium,
          // color: `${theme.palette.text.primary} !important`,
        },
        "& $icon": {
          color: "#fff !important",
          // color: "00e0b0",
        },
      },
    },
    "&.depth-0": {
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
        // color: `${theme.palette.text.primary} !important`,
      },
    },
  },

  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  logoutButton: {
    color: "#263238"
  },

}));

const NavBar = ({ onMobileClose, openMobile, tabView, setSelectedTab }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const location = useLocation();
  const auth = useContext(AuthContext);
  const [isLogout, setIsLogout] = useState(false);
  const confirmationHandler = () => {
    window.localStorage.removeItem("token");
    // window.localStorage.removeItem("email");
    history.push("/");
    window.localStorage.clear();
  };

  const history = useHistory();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location?.pathname]);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      className="srollBoxNav"
      style={{ overflowY: "auto" }}
    >
      <Hidden mdDown>
        <Box
          mb={-1}
          display="flex"
          justifyContent="center"
        // alignItems="center"
        >
          <Logo
            onClick={() => history.push("/dashboard")}
            alt="Logo"
            style={{
              paddingTop: "30px",

              cursor: "pointer",
              width: "106px",
            }}
          />
        </Box>
      </Hidden>
      {open && (
        <ConfirmationDialog
          open={open}
          handleClose={() => setOpen(false)}
          title={"Logout"}
          desc={"Do you want to logout ?"}
          confirmationHandler={confirmationHandler}
          style={{ color: "#fff" }}
        />
      )}
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box my={3} style={{ marginTop: "55px" }}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location?.pathname,
                state: section.tabView,
                setSelectedTab,
              })}
            </List>
          ))}
          <Box style={{ height: "100px !important" }}>
            {/* <ChartList height="38%" /> */}
          </Box>
        </Box>

        <Box className="side_nev_Bottom">
          {sectionsBelow.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {section.items.map((itemList, i) => {
                return (
                  <Button
                    fullWidth
                    key={i}
                    className={classes.Terms}
                    style={{
                      justifyContent: "start",
                      marginTop: "-23px",
                      // paddingLeft: 36,
                      borderRadius: 0,
                      fontSize: "13px",
                      textTransform: "capitalize",
                    }}
                    // onClick={() => history.push(itemList.href)}
                    onClick={() => {
                      itemList.title === "Logout"
                        ? setOpen(true)
                        : history.push(itemList.href);
                    }}
                  >
                    <FaSignOutAlt
                      style={{ fontSize: "15px" }}
                      className={classes.icon}
                    />
                    {itemList.title}
                  </Button>
                );
              })}

              {/* {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })} */}
            </List>
          ))}
        </Box>

        {/* <Box className={classes.logoutButtonBox}>
          <Button
            onClick={() => setIsLogout(true)}
            className={classes.logoutButton}
          >
            <ExitToAppIcon
              style={{
                marginRight: "16px",
                color: "#1756FF",
              }}
            />
            &nbsp; Logout
          </Button>
        </Box>


        {isLogout && (
          <Dialog
            maxWidth="sm"
            fullWidth
            className={classes.dialog}
            open={isLogout}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setIsLogout(false)}
          >
            <DialogContent>
              <Box className={classes.dialougTitle} align="center">
                <Box>
                  <RxCrossCircled className={classes.logoutimgicon} />
                </Box>
                <Typography variant="h4" color="primary" style={{ marginTop: "20px", color: "black" }}>LOGOUT</Typography>
                <Typography variant="h6" color="primary" style={{ marginTop: "20px", color: "black" }}>
                  Are you sure you want to logout ?
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions
              style={{
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Box mt={2} style={{ marginTop: "20px" }}>
                <Button
                  className="modelbtn"
                  color="secondary"

                  style={{ padding: "8px 21px", background: "white", border: "1px solid black" }}
                  onClick={() => setIsLogout(false)}
                >
                  No
                </Button>
                &nbsp; &nbsp;
                <Button
                  className="modelbtnyes"
                  color="secondary"

                  style={{ padding: "8px 21px", background: "white", border: "1px solid black" }}

                  onClick={() => {
                    sessionStorage.removeItem("adminToken");
                    history.push("/");
                    window.location.reload();
                  }}
                >
                  Yes
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        )} */}


      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          style={{ overflowY: "scroll" }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
          style={{ overflowY: "scroll" }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
